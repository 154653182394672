
import { defineComponent, onBeforeMount, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { ApiAccount, ApiBase, ApiTaskManagement } from "@/core/api";
import moment from "moment";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import {
  formatDate,
  formatDateTime,
  setModuleBCN,
} from "@/core/directive/function/common";

interface Option {
  label: string;
  value: string;
}

interface User {
  id: number;
  username: string;
  email: string;
  firstname: string;
  lastname: string;
  language: string;
  advanced_manage: number;
}

interface CallOutRecords {
  id: number;
  name: string;
  description: string;
}

interface SortOption {
  field: string;
  direction: string;
}

interface FilterOption {
  field: string;
  value: string;
  from?: string;
  to?: number;
  condition?: string;
}

export default defineComponent({
  name: "account-overviews",
  components: {},
  setup() {
    const { t } = useI18n();
    const store = useStore();

    const userInfo = ref<User>();

    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const route = useRoute();
    const router = useRouter();

    const total = ref<number>(0);
    const callOutList = ref<Array<CallOutRecords>>([]);

    userInfo.value = store.getters.currentUser;

    watch(
      () => store.getters.currentUser,
      (newVal) => {
        userInfo.value = newVal;
        callOutFormData.value.task_owner = newVal.id;
      }
    );

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      id: route.params.id,
      assigned_cycle: 0,
      assigned_group: 0,
      bc_quota: "",
      birthday: "",
      channel: 6,
      channel_name: "",
      contact_start: "",
      created_at: "",
      created_uid: 0,
      crm_account_id: 0,
      crm_account_status: 10,
      cycle_status: 0,
      deleted_at: "",
      deleted_uid: 0,
      email: "",
      expiry_date: "",
      first_login_time: "",
      first_name: "",
      industry: 0,
      last_name: "",
      lifecycle: "",
      location_of_mobile: "",
      mb_segmentation: [],
      mobile: "",
      mobile_country_code: "",
      personal_id_front: "",
      personal_id_front_url: "",
      personal_id_reverse: "",
      personal_id_reverse_url: "",
      phone: "",
      photo: "",
      photo_url: "",
      registration_date: "",
      salutation: 0,
      source: 0,
      stage: 0,
      status: 10,
      system_id: "",
      updated_at: "",
      updated_uid: 0,
      wechat_account: "",
      wechat_social_connection: [],
      source_value: "",
      tecrm_address: {
        city: "",
        __show: {
          country_iso_2: "",
        },
      },
      __show: {
        source_value: "",
        remark_taggings: "",
        comment_tagging: [],
      },
    });

    const callOutFormData = ref({
      task_owner: "",
      subject: [],
      contact: route.params.id,
      due_date: "",
      action_date: new Date(),
      channel: 0,
      importance: 2,
      comment: "",
      comment_tagging: [],
      task_source: route.path.indexOf("account-conversions") != -1 ? 2 : 1,
    });

    const filterOptions = ref<Array<FilterOption>>([
      {
        field: "contact",
        value: route.params.id.toString(),
      },
    ]);

    const rules = ref({
      subject: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      channel: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      importance: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      comment: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      // comment_tagging: [
      //   {
      //     required: true,
      //     message: "Comment Tagging is required",
      //     trigger: "change",
      //   },
      // ],
    });

    const subjectOptions = ref([]);

    const commentTaggingOptions = ref([
      {
        label: "带货状态",
        options: [
          { label: "带货", value: "254" },
          { label: "很少带货", value: "255" },
          { label: "明确不带货", value: "256" },
          { label: "不知道", value: "257" },
        ],
      },
      {
        label: "带团状态",
        options: [
          { label: "还在带团", value: "259" },
          { label: "没有带团", value: "260" },
          { label: "明确已经转行不做导游", value: "261" },
          { label: "不是导游不带团但属于旅游业内", value: "262" },
          { label: "其他", value: "263" },
        ],
      },
      {
        label: "是否囤货",
        options: [
          { label: "囤货", value: "266" },
          { label: "不囤货", value: "267" },
        ],
      },
      {
        label: "分销对象",
        options: [
          { label: "直客", value: "269" },
          { label: "同行", value: "270" },
          { label: "平台", value: "271" },
        ],
      },
      {
        label: "商品熟知程度",
        options: [
          { label: "商品维度", value: "273" },
          { label: "品牌维度", value: "274" },
          { label: "品类维度", value: "275" },
        ],
      },
      {
        label: "是否接受港澳收货",
        options: [
          { label: "可港澳收货", value: "277" },
          { label: "仅境内收货", value: "278" },
        ],
      },
      {
        label: "微信触达标签",
        options: [
          { label: "咨询回复", value: "280" },
          { label: "触达询货", value: "281" },
          { label: "售后维护", value: "282" },
          { label: "触达成单", value: "283" },
        ],
      },
    ]);

    const lifecycleOptions = ref<Array<Option>>([]);

    const getDropdownOptions = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["influencer_lifecycle", "call_out_subject"],
      });
      if (data.code == 0) {
        lifecycleOptions.value = data.data.influencer_lifecycle.items;
        subjectOptions.value = data.data.call_out_subject.items;
      }
    };

    const getAccountInfo = async () => {
      loading.value = true;
      const { data } = await ApiAccount.getAccountInfo({
        id: route.params.id,
      });
      loading.value = false;
      if (data.code == 0) {
        formData.value = data.data;
      }
    };

    const getLifecycleStatus = (val) => {
      let str = "";
      lifecycleOptions.value.map((item, index) => {
        if (item.value == val) {
          str = '<span class="badge badge-success">' + item.label + "</span>";
        }
      });
      return str;
    };

    const getCrmAccountStatus = (val) => {
      const status = new Map([
        ["0", t("influencerAccount.draft")],
        ["5", t("influencerAccount.inactivated")],
        ["10", t("influencerAccount.suspended")],
        ["12", t("influencerAccount.Inivited")],
        ["20", t("influencerAccount.active")],
        ["90", t("influencerAccount.Disabled")],
      ]);
      if (val == 0 || val == 5) {
        return (
          '<span class="badge badge-light">' +
          status.get(String(val)) +
          "</span>"
        );
      } else if (val == 10) {
        return (
          '<span class="badge badge-warning">' +
          status.get(String(val)) +
          "</span>"
        );
      } else if (val == 12) {
        return (
          '<span class="badge badge-info">' +
          status.get(String(val)) +
          "</span>"
        );
      } else if (val == 90) {
        return (
          '<span class="badge badge-secondary">' +
          status.get(String(val)) +
          "</span>"
        );
      } else if (val == 20) {
        return (
          '<span class="badge badge-success">' +
          status.get(String(val)) +
          "</span>"
        );
      }
    };

    const getCallOutRecordsList = (
      page: number,
      pageSize: number,
      search?: string,
      sortOptions?: Array<SortOption>,
      filterOptions?: Array<FilterOption>
    ) => {
      loading.value = true;
      ApiTaskManagement.getCallOutRecordsList({
        page: page,
        page_size: pageSize,
        search_value: search,
        sort_orders: sortOptions,
        filter_group: filterOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            callOutList.value = data.data.items;
            total.value = data.data.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submitCallOut = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiTaskManagement.addCallOutRecords(callOutFormData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                getCallOutRecordsList(
                  1,
                  6,
                  "",
                  [{ field: "action_date", direction: "desc" }],
                  filterOptions.value
                );
                showFormSubmitSuccessMsg();
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const backToList = () => {
      if (route.path.indexOf("account-conversions") != -1) {
        router.push("/interactive-marketing/account-conversions");
      } else {
        router.push("/influencer-platform/influencer-accounts");
      }
    };

    onBeforeMount(() => {
      getAccountInfo();
      getDropdownOptions();
    });

    onMounted(() => {
      setModuleBCN(t, route, router);

      getCallOutRecordsList(
        1,
        6,
        "",
        [{ field: "action_date", direction: "desc" }],
        filterOptions.value
      );
    });

    return {
      t,
      formatDate,
      formatDateTime,
      loading,
      formData,
      callOutFormData,
      rules,
      formRef,
      submitButton,
      subjectOptions,
      commentTaggingOptions,
      total,
      callOutList,
      userInfo,
      submitCallOut,
      backToList,
      getCrmAccountStatus,
      getLifecycleStatus,
    };
  },
});
